.top-nav {
    cursor: none;
    background-color: #333;
    padding: 1rem;
}

.top-nav ul {
    list-style: none;
    display: flex;
    gap: 1rem;
}

.top-nav a {
    cursor: none;
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.top-nav a:hover {
    color: rgb(148, 59, 232);
}

.top-nav .active-link {
    color: rgb(148, 59, 232);
  }
  
.top-nav .inactive-link {
    color: white;
    font-weight: bold;
    text-decoration: none;
}