.Page {
    text-align: center;
    cursor: none;
  }
  
  .Page-logo {
    height: 25vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Page-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .Page-header {
    background-color: #282c34;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Page-link {
    color: #61dafb;
  }
  
  @keyframes Page-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .Text-Block {
    text-align: left;
    font-size: calc(10px + 1.25vmin);
    border: none;
    padding: 20px;
    margin: 50px;
    border-radius: 10px;
    background-color: #f0f0f01d;
  }

  .Text-Block a {
    cursor: none;
    color: #0096d6;
    font-weight: bold;
    text-decoration: none;
  }
  .Text-Block a:visited {
    color: #0096d6;
    font-weight: bold;
    text-decoration: none;
  }
  .dropdown-arrow {
    color: white;
    background: none;
    border: none;
    fontSize: 1.5rem;
  }
  .pdf-container {
    margin-top: 15px;
  }
  .custom-cursor {
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: transform 0.3s ease;
    z-index: 9999;
  }